import React, { useEffect } from 'react';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../logo.png";
import icon from "../../assets/assignment.png"
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";

function NavigationBar() {
  const location = useLocation();

  // Scroll to the section when the hash changes
  useEffect(() => {
    const sectionId = location.hash;
    if (sectionId) {
      const element = document.getElementById(sectionId.slice(1));  // Remove the '#' character
      if (element) {
        element.scrollIntoView(); // Scroll to the element
      }
    }
  }, [location]);
  return (
    <Navbar expand="lg" className="navbar">
      <Container>
        <Navbar.Brand href="/">
          <img
            src={logo}
            alt="logo"
            className="d-flex align-items-center"
            style={{ width: "10rem" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav ms-auto">
            <Nav.Link
              as={Link}
              to="/#main-body"
              className="text-decoration-none text-light"
            >
              HOME
            </Nav.Link>
            <Nav.Link as={Link} to="/services#service-body">
              SERVICES
            </Nav.Link>
            <Nav.Link as={Link} to="/partners#partners-body">
              PARTNERS
            </Nav.Link>
            <Nav.Link as={Link} to="/howItWorks#how-body">
              HOW IT WORKS
            </Nav.Link>
            <Nav.Link as={Link} to="/about#about-body">
              ABOUT
            </Nav.Link>
            <Nav.Link className='apply-now' as={Link} to="/#about-form">
             <span>
              <img className='apply-now-ico' alt="icon" src={icon}></img>
                CONTACT US
             </span>

            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
